import React from "react";
import { Chip, IconButton, makeStyles, Typography } from "@material-ui/core";
import CopyrightIcon from "@material-ui/icons/Copyright";
import { Link } from "gatsby";
import { Facebook, LinkedIn } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-end",
    },
  },
  item: {
    marginLeft: theme.spacing(3),
    "& a": {
      color: "#fff",
      textDecoration: "none",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
    },
  },
}));

export const MetaNav = (props: any) => {
  const classes = useStyles();
  return (
    <div className={`${classes.root} ${props.className}`}>
      <div className={classes.item}>
        <Typography>
          <Link to="/impressum">Impressum</Link>
        </Typography>
      </div>
      <div className={classes.item}>
        <IconButton
          component="a"
          href="https://www.linkedin.com/in/mehdi-tajdari-580b2378"
          target="_blank"
        >
          <LinkedIn />
        </IconButton>
        <Chip color="secondary" icon={<CopyrightIcon />} />
      </div>
    </div>
  );
};
