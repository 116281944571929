import React, { ReactNode } from "react";
import { Link as NativeLink, GatsbyLinkProps } from "gatsby";
import { Link as MuiLink } from "@material-ui/core";
import { TypographyProps } from "@material-ui/core/Typography";

type LinkProps = GatsbyLinkProps<{to: string}> &
  TypographyProps & {
    children: ReactNode;
  };

const ForwardedLink = React.forwardRef((props: any, ref) => (
  <NativeLink ref={ref} {...props} />
));

export const Link = (props: LinkProps) => (
  <MuiLink {...props} component={ForwardedLink}>
    {props.children}
  </MuiLink>
);
