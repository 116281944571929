import React from "react";
import { makeStyles, Typography, Container } from "@material-ui/core";
import { MenuItem } from "./layout";
import { Link } from "./Link";
import { MetaNav } from "./MetaNav";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "flex-end",
  },
  navigation: {
    flexGrow: 1,
  },
  menuItem: {
    display: "block",
    width: "auto",
    "&:hover": {
      textDecoration: "none",
    },
  },
  linkWrapper: {
    display: "inline-block",
  },
});

type NavigationProps = {
  menuItems: MenuItem[];
};

export const Navigation = ({ menuItems }: NavigationProps) => {
  const classes = useStyles();
  return (
    <Container>
      <div className={classes.root}>
        <div className={classes.navigation}>
          <span className={classes.linkWrapper}>
            {menuItems.map((item, index) => (
              <Link
                key={index}
                className={classes.menuItem}
                color="inherit"
                to={item.to}
              >
                <Typography variant="h6" component="span">
                  {item.name}
                </Typography>
              </Link>
            ))}
          </span>
        </div>
        <div>
          <MetaNav />
        </div>
      </div>
    </Container>
  );
};
