import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Logo } from "./Logo";
import { MenuOverlay } from "./MenuOverlay";
import { MenuItem } from "./layout";
import { Container } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    zIndex: 999,
    paddingTop: "30px",
  },
});

type HeaderProps = {
  menuItems: MenuItem[];
};

const Header = ({ menuItems }: HeaderProps) => {
  const classes = useStyles();
  return (
    <Container>
      <div className={classes.root}>
        <div>
          <Logo />
          <MenuOverlay menuItems={menuItems} />
        </div>
      </div>
    </Container>
  );
};

export default Header;
