import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { PaletteProps } from "@material-ui/system";

type HighlightProps = PaletteProps & {
  children: ReactNode;
};

const useStyles = makeStyles({
  root: {
    paddingTop: "5rem",
    paddingBottom: "5rem",
  },
});

export const Highlight = ({ children, color, bgcolor }: HighlightProps) => {
  const classes = useStyles();
  return (
    <Box color={color} bgcolor={bgcolor} className={classes.root}>
      {children}
    </Box>
  );
};
