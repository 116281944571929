import React from "react";
import { Portal } from "@material-ui/core";

type OverlayPortalProps = {
  children: React.ReactChild;
};

const OverlayPortal = ({ children }: OverlayPortalProps) => {
  if (typeof window !== "undefined") {
    return <Portal container={document.body}>{children}</Portal>;
  }
  return null;
};

export default OverlayPortal;
