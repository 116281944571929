import React, { useEffect, useRef, useState } from "react";
import LogoSrc from "../images/logo_.svg";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "gatsby";
import theme from "./theme";

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    padding: "10px 8px 8px 10px",
    transition: "width .2s ease-in-out",
  },
  logoLink: {
    display: "block",
  },
  logo: {
    transition: "width .2s ease-in-out",
    width: 100,
    [theme.breakpoints.up("sm")]: {
      width: 130,
    }
  },
});

export const Logo = () => {
  const classes = useStyles();


  return (
    <div className={classes.root}>
      <Link className={classes.logoLink} to="/">
        <img
          src={LogoSrc}
          className={classes.logo}
        />
      </Link>
    </div>
  );
};
