import React from "react";
import { Highlight } from "./Highlight";
import { MenuItem } from "./layout";
import { Navigation } from "./Navigation";

type FooterProps = {
  menuItems: MenuItem[];
};

export const Footer = ({ menuItems }: FooterProps) => {
  return (
    <Highlight color="secondary.contrastText" bgcolor="secondary.dark">
      <Navigation menuItems={menuItems} />
    </Highlight>
  );
};
