import React from "react";
import PropTypes from "prop-types";
import Header from "./header";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import "../styles/base.css";
import theme from "./theme";
import { Footer } from "./Footer";
import Helmet from "react-helmet";

type LayoutProps = {
  children: React.ReactNode;
};

export type MenuItem = {
  name: string;
  to: string;
};

const menuItems: MenuItem[] = [
  {
    name: "Berufsträger",
    to: "/berufstraeger",
  },
  {
    name: "Leistungen",
    to: "/leistungen",
  },
  {
    name: "Aktuelles",
    to: "/aktuelles",
  },
  {
    name: "Karriere",
    to: "/karriere",
  },
  {
    name: "Kontakt",
    to: "/kontakt",
  },
];

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  content: {
    flexGrow: 1,
  },
});

const Layout = ({ children }: LayoutProps) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <link rel="icon" type="image/x-icon" href="/mtstb.ico" />
      </Helmet>
      <div className={classes.root}>
        <Header menuItems={menuItems} />
        <div className={classes.content}>{children}</div>
        <Footer menuItems={menuItems}></Footer>
      </div>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
