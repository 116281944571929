import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    fontFamily: '"IBM Plex Sans", "Helvetica", "Arial", sans-serif'
  },
  palette: {
    primary: {
			main: "#fff",
			contrastText: "#000"
		},
    secondary: {
			main: "#000",
			contrastText: "#fff"
		}
	}
});



export default responsiveFontSizes(theme);;
