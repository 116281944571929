import React, { useState, useRef, useEffect } from "react";
import { Fab, Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import OverlayPortal from "./OverlayPortal";
import { useSpring, animated, useTransition, useChain } from "react-spring";
import { Link } from "gatsby";
import { MetaNav } from "./MetaNav";
import { MenuItem } from "./layout";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    alignItems: "flex-end",
    justifyContent: "space-between",
    flexDirection: "column",
    display: "flex",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 998,
  },
  overlayContainer: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 56,
    height: 56,
    zIndex: 998,
  },
  overlay: {
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
    width: "230vw",
    height: "230vw",
    transform: "scale(0)",
    borderRadius: "200vw",
    flex: "0 0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "430vw",
      height: "430vw",
    },
  },
  circle: {
    zIndex: 999,
  },
  menuContainer: {
    alignSelf: "flex-start",
    zIndex: 998,
    color: "#fff",
  },
  link: {
    color: "#fff",
    textDecoration: "none",
    marginTop: 20,
    display: "block",
  },
  metaNav: {
    right: theme.spacing(6),
    bottom: theme.spacing(6),
    zIndex: 998,
  },
}));

type MenuOverlayProps = {
  menuItems: MenuItem[];
};

export const MenuOverlay = ({ menuItems }: MenuOverlayProps) => {
  const [menuToggle, setMenuToggle] = useState(false);
  const classes = useStyles();
  const metaNavSpring = useSpring({
    opacity: menuToggle ? 1 : 0,
  });
  const containerSpringRef = useRef(null);
  const containerSpring = useSpring({
    ref: containerSpringRef,
    from: { bottom: "auto" },
    to: { bottom: menuToggle ? 0 : "auto" },
  });
  const overlaySpringRef = useRef(null);
  const overlaySpring = useSpring({
    ref: overlaySpringRef,
    from: { transform: "scale(0)" },
    to: { transform: `scale(${menuToggle ? 1 : 0})` },
  });
  const menuTransitionRef = useRef(null);
  const menuTransition = useTransition(
    menuToggle ? menuItems : [],
    (item) => item.name,
    {
      ref: menuTransitionRef,
      from: { transform: "translateX(-50%)", opacity: 0 },
      enter: { transform: "translateX(0)", opacity: 1 },
      leave: {
        transform: "translateX(-50%)",
        opacity: 0,
        position: "absolute",
      },
      trail: 200,
      delay: 500,
    }
  );

  useChain(
    menuToggle
      ? [containerSpringRef, overlaySpringRef, menuTransitionRef]
      : [menuTransitionRef, overlaySpringRef, containerSpringRef],
    [0, menuToggle ? 0.1 : 0.6, menuToggle ? 0 : 1]
  );

  useEffect(() => {
    if (menuToggle) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [menuToggle]);

  const AnimatedContainer = animated(Container);

  return (
    <OverlayPortal>
      <AnimatedContainer className={classes.root} style={containerSpring}>
        <Fab
          color="primary"
          className={classes.circle}
          onClick={() => setMenuToggle(!menuToggle)}
        >
          <MenuIcon />
        </Fab>
        <div className={classes.overlayContainer}>
          <animated.div
            className={classes.overlay}
            style={overlaySpring}
          ></animated.div>
        </div>
        <div className={classes.menuContainer}>
          {menuTransition.map(
            ({ item, props, key }: any) =>
              item && (
                <animated.div key={key} style={props}>
                  <Link className={classes.link} to={item.to}>
                    <Typography variant="h4" component="span">
                      {item.name}
                    </Typography>
                  </Link>
                </animated.div>
              )
          )}
        </div>
        <animated.div className={classes.metaNav} style={metaNavSpring}>
          <MetaNav />
        </animated.div>
      </AnimatedContainer>
    </OverlayPortal>
  );
};
